import React,{useState,useRef,useEffect} from 'react';
import loadernew from '../images/loadernew.gif';
import Header from './Header'
import Footer from './Footer'
import { Helmet } from "react-helmet";

function Induatrialrooftops() {
  const [isLoading, setIsLoading] = useState(true)

  const vidRef = useRef();
  const loadimg = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }
  useEffect(() => {

    loadimg();
  }, []);
  return (
    <div>
           <Helmet>
        <meta charSet="utf-8" />
        <title>
        Best Solar Rooftops for Industries in Hyderabad | Sri Balaji Solar Energies
        </title>
        <meta
          name="description"
          content="SBSC offers tailored grid-tied solar PV systems for homes and businesses. Contact us for industrial and commercial solar solutions!"
        />
        <meta
          name="keywords"
          content="industrial solar panel installation,
solar panels for industrial buildings,
industrial solar installation company,
industrial solar installation"
        />
        <link rel="canonical" href="https://www.sribalajisolarenergies.com/industrial-rooftops" />
      </Helmet>

 {isLoading == true ? (

<>
  <div
    style={{ zIndex: "9999999999999", height: "420px", }}
    className="text-center mt-3 mb-5"
  >
    <img src={loadernew} height="140px" style={{marginTop:'250px'}}></img>

  </div>
</>
) : (
<>

      <Header />
      <div className="page-header parallaxie">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
            
              <div className="page-header-box">
                <h1 className="text-anime">Induatrial Rooftops</h1>
                <div className="post-single-meta wow fadeInUp" data-wow-delay="0.25s">
                  <ul>
                  <li><a href="/">Home</a></li>
                    <li>Induatrial Rooftops</li>
                  </ul>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
      <div className="page-single-post">
        <div className="container">
          <div className="row">
            <div className="col-md-12">

              <div className="post-featured-image wow fadeInUp" data-wow-delay="0.25s">
                <figure className="image-anime">
                  <img src="images/post-1.jpg" alt="" />
                </figure>
              </div>

              <div className="post-content">
                <div className="post-entry">
                  <p>Industrial Solar Rooftops SBSC Solar Systems is an established firm in India known for providing quality centric solar panels. Here’s introducing our cutting edge grid-tied solar PV systems which seamlessly integrate with your existing power grid, allowing you to harness the sun's energy to power your home or business efficiently. This grid tied solar PV Systems are eco-friendly and also cost effective alternative to the traditional energy sources. Grid-tied solar PV Systems effectively reduces the electricity bills and gives the energy independence. These Grid-tied solar PV Systems are well known for their simple and simplicity & high end performance. Grid-tied solar PV Systems captures the sunlight and convert it into the clean energy, meanwhile inverters ensures seamless integration by optimizing energy production and thereby reducing power wastage. Our grid-tied solar PV systems are tailored to meet your specific energy needs, whether you're a homeowner or a business owner. Reach out to SBSC now for any queries on Industrial Solar Panel and Commercial Solar System.</p>
                  
                </div>

                {/* <div className="row align-items-center">
                  <div className="col-lg-8">

                    <div className="post-tags">
                      Tags :
                      <a href="#">Energy</a>
                      <a href="#">Renewable</a>
                      <a href="#">Solar</a>
                    </div>

                  </div>
                  <div className="col-lg-4">

                    <div className="post-social-sharing">
                      <ul>
                        <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                        <li><a href="#"><i className="fab fa-instagram" /></a></li>
                        <li><a href="#"><i className="fab fa-twitter" /></a></li>
                      </ul>
                    </div>

                  </div>
                </div> */}
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="service-benefits">
        <div className="row">
          <div className="col-md-12">
            <div className="service-benefits-title">
              <h2 className="text-anime">Benefits of Solar Energy</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
         
            <div className="benefits-item">
              <div className="icon-box">
                <img src="images/icon-benefits-1.svg" alt="" />
              </div>
              <h3>Renewable Energy</h3>
              <p>Renewable energy comes from unlimited, naturally replenished resources, such as the sun, tides, and wind.</p>
            </div>
      
          </div>
          <div className="col-lg-4 col-md-6">
          
            <div className="benefits-item">
              <div className="icon-box">
                <img src="images/icon-benefits-2.svg" alt="" />
              </div>
              <h3>Energy Saving</h3>
              <p>Turn off your lights: It will also help in reducing the unnecessary energy consumption.</p>
            </div>
           
          </div>
          <div className="col-lg-4 col-md-6">
           
            <div className="benefits-item">
              <div className="icon-box">
                <img src="images/icon-benefits-3.svg" alt="" />
              </div>
              <h3>Easy Installation</h3>
              <p>Home solar panel installations require electrical, roofing, and other skilled work.</p>
            </div>
          
          </div>
          <div className="col-lg-4 col-md-6">     
          <div className="benefits-item">
              <div className="icon-box">
                <img src="images/icon-benefits-4.svg" alt="" />
              </div>
              <h3>Energy Solution</h3>
              <p>The sun provides enough energy in just ONE HOUR to meet our global energy needs for a whole year.</p>
            </div>
   
          </div>
          <div className="col-lg-4 col-md-6">
      
            <div className="benefits-item">
              <div className="icon-box">
                <img src="images/icon-benefits-5.svg" alt="" />
              </div>
              <h3>Technical Support</h3>
              <p>There are three primary technologies by which solar energy is harnessed: photovoltaics (PV), which directly convert light to electricity.</p>
            </div>
           
          </div>
          <div className="col-lg-4 col-md-6">
           
            <div className="benefits-item">
              <div className="icon-box">
                <img src="images/icon-benefits-6.svg" alt="" />
              </div>
              <h3>Solar Maintenance</h3>
              <p>Solar companies recommend cleaning your solar panels at least twice a year, regardless of the weather conditions and where you live.</p>
            </div>
       
          </div>
        </div>
      </div>
      <Footer />
      </>
)}
    </div>
  )
}

export default Induatrialrooftops