import React, { useRef, useState, useEffect } from "react";
import loadernew from "../images/loadernew.gif";
import Header from "./Header";
import Footer from "./Footer";
import solarfencing from "../images/Products/solarfencing.jpg";
import { Helmet } from "react-helmet";
function Solarfencingsystem() {
  const [isLoading, setIsLoading] = useState(true);

  const vidRef = useRef();
  const loadimg = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
  useEffect(() => {
    loadimg();
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Solar Fencing Systems installation for Agriculture and Security
        </title>
        <meta
          name="description"
          content="Sri Balaji Solar offers reliable solar fencing systems for agriculture and security, featuring solar backup and customizable, affordable solutions."
        />
        <meta
          name="keywords"
          content="crown solar power fencing systems price,
crown solar power fencing system,
crown solar fencing,
cost of solar electric fence,
solar fencing near me,
solar electric fence installation"
        />
        <link
          rel="canonical"
          href="https://www.sribalajisolarenergies.com/SolarFencingSystem"
        />
      </Helmet>

      {isLoading == true ? (
        <>
          <div
            style={{ zIndex: "9999999999999", height: "420px" }}
            className="text-center mt-3 mb-5"
          >
            <img
              src={loadernew}
              height="140px"
              style={{ marginTop: "250px" }}
            ></img>
          </div>
        </>
      ) : (
        <>
          <Header />
          <div className="page-header parallaxie">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-header-box">
                    <h1 className="text-anime">Solar fencing System</h1>
                    <div
                      className="post-single-meta wow fadeInUp"
                      data-wow-delay="0.25s"
                    >
                      <ul>
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>Solar fencing System</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-single-post">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="post-featured-image wow fadeInUp"
                    data-wow-delay="0.25s"
                  >
                    <figure className="image-anime">
                      <img src={solarfencing} alt="" />
                    </figure>
                  </div>

                  <div className="post-content">
                    <div className="post-entry">
                      <p>
                        SRI BALAJI SOLAR ENERGIES initiated to workout the Solar
                        Power Systems with standard Solar fencing system is a
                        popular and sought system for protecting premises for
                        Agriculture, Security or High security applications.
                        Requirement of Solar fencing, (which is also called as
                        Power fencing) with Solar backup, has increased its
                        appeal now, mainly because of awareness and availability
                        of affordable brands. There is a great focus on ensuring
                        that the specifications and demands are met.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="service-benefits">
            <div className="row">
              <div className="col-md-12">
                <div className="service-benefits-title">
                  <h2 className="text-anime">Benefits of Solar Energy</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="benefits-item">
                  <div className="icon-box">
                    <img src="images/icon-benefits-1.svg" alt="" />
                  </div>
                  <h3>Renewable Energy</h3>
                  <p>
                    Renewable energy comes from unlimited, naturally replenished
                    resources, such as the sun, tides, and wind.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="benefits-item">
                  <div className="icon-box">
                    <img src="images/icon-benefits-2.svg" alt="" />
                  </div>
                  <h3>Energy Saving</h3>
                  <p>
                    Turn off your lights: It will also help in reducing the
                    unnecessary energy consumption.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="benefits-item">
                  <div className="icon-box">
                    <img src="images/icon-benefits-3.svg" alt="" />
                  </div>
                  <h3>Easy Installation</h3>
                  <p>
                    Home solar panel installations require electrical, roofing,
                    and other skilled work.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="benefits-item">
                  <div className="icon-box">
                    <img src="images/icon-benefits-4.svg" alt="" />
                  </div>
                  <h3>Energy Solution</h3>
                  <p>
                    The sun provides enough energy in just ONE HOUR to meet our
                    global energy needs for a whole year.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="benefits-item">
                  <div className="icon-box">
                    <img src="images/icon-benefits-5.svg" alt="" />
                  </div>
                  <h3>Technical Support</h3>
                  <p>
                    There are three primary technologies by which solar energy
                    is harnessed: photovoltaics (PV), which directly convert
                    light to electricity.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="benefits-item">
                  <div className="icon-box">
                    <img src="images/icon-benefits-6.svg" alt="" />
                  </div>
                  <h3>Solar Maintenance</h3>
                  <p>
                    Solar companies recommend cleaning your solar panels at
                    least twice a year, regardless of the weather conditions and
                    where you live.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}

export default Solarfencingsystem;
