import React, { useState, useRef, useEffect } from 'react';
import loadernew from '../images/loadernew.gif';
import Header from './Header'
import Footer from './Footer'
import { Helmet } from "react-helmet";

function Commercialrooftops() {
  const [isLoading, setIsLoading] = useState(true)

  const vidRef = useRef();
  const loadimg = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }
  useEffect(() => {

    loadimg();
  }, []);
  return (
    <div>
                 <Helmet>
        <meta charSet="utf-8" />
        <title>
        A Grade Solar Rooftop Installation Service for Commercials | Sri Balaji Solar Energies
        </title>
        <meta
          name="description"
          content="For commercial apartments, we provide premium solar roofing. 99% of my clients are happy with my services."
        />
        <meta
          name="keywords"
          content="commercial solar panel installers near me,
commercial solar companies near me,
commercial solar panel installation near me,
commercial and industrial solar companies"
        />
        <link rel="canonical" href="https://www.sribalajisolarenergies.com/commercial-rooftops" />
      </Helmet>


      {isLoading == true ? (

<>
  <div
    style={{ zIndex: "9999999999999", height: "420px", }}
    className="text-center mt-3 mb-5"
  >
    <img src={loadernew} height="140px" style={{ marginTop: '250px' }}></img>

  </div>
</>
) : (
<>
      <Header />
      <div className="page-header parallaxie">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
            
              <div className="page-header-box">
                <h1 className="text-anime">Commercial Rooftops</h1>
                <div className="post-single-meta wow fadeInUp" data-wow-delay="0.25s">
                  <ul>
                  <li><a href="/">Home</a></li>
                    <li>Commercial Rooftops</li>
                  </ul>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
      <div className="page-single-post">
        <div className="container">
          <div className="row">
            <div className="col-md-12">

              <div className="post-featured-image wow fadeInUp" data-wow-delay="0.25s">
                <figure className="image-anime">
                  <img src="images/post-1.jpg" alt="" />
                </figure>
              </div>

              <div className="post-content">
                <div className="post-entry">
                  <p>Commercial Solar Rooftops SBSC Energy Systems, A pioneer in the industry of solar energy. SBSC is dedicated in providing state of the art Commercial rooftop Solutions for a reliable & sustainable future. Commercial Solar Rooftops benefits in maximizing energy independence and in providing the most efficient way of generating electricity. With years of expertise in the renewable energy sector, we pride ourselves on delivering high-performance solar panels that not only reduce your carbon footprint but also contribute to long-term energy savings & also rapid ROI for commercial organizations. SBSC is dedicated in providing tailored solar solutions that matches the needs of our customer’s commercial needs. We are equipped with a solid team of experts who ensures seamless experience from concept to installation, leveraging the latest advancements in solar technology. From reducing electricity bills to maximizing energy efficiency, we empower you to take control of your energy future. For our precise & reliable services SBSC stands tall as the Best solar solutions provider in commercial category.</p>
                 
                </div>

                {/* <div className="row align-items-center">
                  <div className="col-lg-8">

                    <div className="post-tags">
                      Tags :
                      <a href="#">Energy</a>
                      <a href="#">Renewable</a>
                      <a href="#">Solar</a>
                    </div>

                  </div>
                  <div className="col-lg-4">

                    <div className="post-social-sharing">
                      <ul>
                        <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                        <li><a href="#"><i className="fab fa-instagram" /></a></li>
                        <li><a href="#"><i className="fab fa-twitter" /></a></li>
                      </ul>
                    </div>

                  </div>
                </div> */}
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="service-benefits">
        <div className="row">
          <div className="col-md-12">
            <div className="service-benefits-title">
              <h2 className="text-anime">Benefits of Solar Energy</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
         
            <div className="benefits-item">
              <div className="icon-box">
                <img src="images/icon-benefits-1.svg" alt="" />
              </div>
              <h3>Renewable Energy</h3>
              <p>Renewable energy comes from unlimited, naturally replenished resources, such as the sun, tides, and wind.</p>
            </div>
      
          </div>
          <div className="col-lg-4 col-md-6">
          
            <div className="benefits-item">
              <div className="icon-box">
                <img src="images/icon-benefits-2.svg" alt="" />
              </div>
              <h3>Energy Saving</h3>
              <p>Turn off your lights: It will also help in reducing the unnecessary energy consumption.</p>
            </div>
           
          </div>
          <div className="col-lg-4 col-md-6">
           
            <div className="benefits-item">
              <div className="icon-box">
                <img src="images/icon-benefits-3.svg" alt="" />
              </div>
              <h3>Easy Installation</h3>
              <p>Home solar panel installations require electrical, roofing, and other skilled work.</p>
            </div>
          
          </div>
          <div className="col-lg-4 col-md-6">     
          <div className="benefits-item">
              <div className="icon-box">
                <img src="images/icon-benefits-4.svg" alt="" />
              </div>
              <h3>Energy Solution</h3>
              <p>The sun provides enough energy in just ONE HOUR to meet our global energy needs for a whole year.</p>
            </div>
   
          </div>
          <div className="col-lg-4 col-md-6">
      
            <div className="benefits-item">
              <div className="icon-box">
                <img src="images/icon-benefits-5.svg" alt="" />
              </div>
              <h3>Technical Support</h3>
              <p>There are three primary technologies by which solar energy is harnessed: photovoltaics (PV), which directly convert light to electricity.</p>
            </div>
           
          </div>
          <div className="col-lg-4 col-md-6">
           
            <div className="benefits-item">
              <div className="icon-box">
                <img src="images/icon-benefits-6.svg" alt="" />
              </div>
              <h3>Solar Maintenance</h3>
              <p>Solar companies recommend cleaning your solar panels at least twice a year, regardless of the weather conditions and where you live.</p>
            </div>
       
          </div>
        </div>
      </div>
      <Footer />
      </>
      )}
    </div>
  )
}

export default Commercialrooftops