import React, { useState, useRef, useEffect } from "react";
import loadernew from "../images/loadernew.gif";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

function Residencialrooftops() {
  const [isLoading, setIsLoading] = useState(true);

  const vidRef = useRef();
  const loadimg = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
  useEffect(() => {
    loadimg();
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Top Recidencial solar panels - Sri Balaji solar energies</title>
        <meta
          name="description"
          content="Our residential solar roofing is long-lasting and robustly constructed.Contact Sri Balaji solars for a quote on solar panels for  houses."
        />
        <meta
          name="keywords"
          content="solar panels for home near me,
residential solar panel installers near me,
best residential solar companies near me,
Best solar panels for residential use"
        />
        <link
          rel="canonical"
          href="https://www.sribalajisolarenergies.com/Residential-Rooftops"
        />
      </Helmet>

      {isLoading == true ? (
        <>
          <div
            style={{ zIndex: "9999999999999", height: "420px" }}
            className="text-center mt-3 mb-5"
          >
            <img
              src={loadernew}
              height="140px"
              style={{ marginTop: "250px" }}
            ></img>
          </div>
        </>
      ) : (
        <>
          <Header />
          <div className="page-header parallaxie">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-header-box">
                    <h1 className="text-anime">Residencial Rooftops</h1>
                    <div
                      className="post-single-meta wow fadeInUp"
                      data-wow-delay="0.25s"
                    >
                      <ul>
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>Residencial Rooftops</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-single-post">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="post-featured-image wow fadeInUp"
                    data-wow-delay="0.25s"
                  >
                    <figure className="image-anime">
                      <img src="images/post-1.jpg" alt="" />
                    </figure>
                  </div>

                  <div className="post-content">
                    <div className="post-entry">
                      <p>
                        Solar Energy Systems is a leading name in the industry
                        of solar power. With all the expertise we earned over
                        these years Suntek is now one of the notable name for
                        households. Solar Rooftops in residential sector has
                        wide array of benefits which includes the reduction of
                        carbon foot print and reduction on high electricity
                        expenses. Our cutting-edge solar technology seamlessly
                        integrates into your home's architecture, transforming
                        your roof into a clean energy powerhouse. Solar Rooftops
                        for residential spaces offer energy independence to the
                        users by harnessing the abundant power of sun. Our team
                        of experts ensures a hassle-free transition to solar
                        energy, from the initial consultation to the
                        professional installation of your customized solar
                        system. Our cutting edge solar rooftop comes with robust
                        build quality and high durability. Join the growing
                        community of environmentally conscious homeowners who
                        have embraced solar energy with Suntek. Contact Suntek
                        to get a quote on Solar Panels for Home Price in India.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="service-benefits">
            <div className="row">
              <div className="col-md-12">
                <div className="service-benefits-title">
                  <h2 className="text-anime">Benefits of Solar Energy</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="benefits-item">
                  <div className="icon-box">
                    <img src="images/icon-benefits-1.svg" alt="" />
                  </div>
                  <h3>Renewable Energy</h3>
                  <p>
                    Renewable energy comes from unlimited, naturally replenished
                    resources, such as the sun, tides, and wind.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="benefits-item">
                  <div className="icon-box">
                    <img src="images/icon-benefits-2.svg" alt="" />
                  </div>
                  <h3>Energy Saving</h3>
                  <p>
                    Turn off your lights: It will also help in reducing the
                    unnecessary energy consumption.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="benefits-item">
                  <div className="icon-box">
                    <img src="images/icon-benefits-3.svg" alt="" />
                  </div>
                  <h3>Easy Installation</h3>
                  <p>
                    Home solar panel installations require electrical, roofing,
                    and other skilled work.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="benefits-item">
                  <div className="icon-box">
                    <img src="images/icon-benefits-4.svg" alt="" />
                  </div>
                  <h3>Energy Solution</h3>
                  <p>
                    The sun provides enough energy in just ONE HOUR to meet our
                    global energy needs for a whole year.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="benefits-item">
                  <div className="icon-box">
                    <img src="images/icon-benefits-5.svg" alt="" />
                  </div>
                  <h3>Technical Support</h3>
                  <p>
                    There are three primary technologies by which solar energy
                    is harnessed: photovoltaics (PV), which directly convert
                    light to electricity.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="benefits-item">
                  <div className="icon-box">
                    <img src="images/icon-benefits-6.svg" alt="" />
                  </div>
                  <h3>Solar Maintenance</h3>
                  <p>
                    Solar companies recommend cleaning your solar panels at
                    least twice a year, regardless of the weather conditions and
                    where you live.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}

export default Residencialrooftops;
